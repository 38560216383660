// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import 'jquery-ui/ui/widgets/tooltip';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  if ($(window).scrollTop() > 40) {
    $('.header').addClass('sticky-header');
  } else {
    $('.header').removeClass('sticky-header');
  }
});
$(document).on('gform_post_render', function () {
  $('.gform_drop_instructions').text('Drop files here');
  $('.federal-drop .gform_drop_instructions').text(
    'Download your federal tax exemption letter'
  );
  $('.fin-drop .gform_drop_instructions').text(
    'Download your Financial statements audited'
  );
  $('.oper-drop .gform_drop_instructions').text(
    'Download your operating budget'
  );
  $('.laws-drop .gform_drop_instructions').text(
    "Download your copy of organization's by-laws."
  );
  $('.school-drop .gform_drop_instructions').text(
    'Download your school-based grant request'
  );
  $('.upload-receipts .gform_drop_instructions').text('Upload your receipts');
});

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  // Обработчик нажатия на кнопку Apply
  $('#apply-fee-gravity').click(function (e) {
    e.preventDefault(); // Предотвратить стандартное поведение кнопки

    // Получаем значение из поля "Total without fee" (поле с классом total-without-fee)
    var totalWithoutFee =
      parseFloat(
        $('.total-without-fee .ginput_container .ginput_total')
          .text()
          .replace('$', '')
          .trim()
      ) || 0; // Получаем сумму из отображаемого текста

    // Рассчитываем комиссию (0.03% от значения)
    var fee = totalWithoutFee * 0.03;

    // Получаем поле для общей суммы (скрытое поле с классом total-with-fee)
    var totalField = $('.total-with-fee .ginput_container input.gform_hidden'); // Скрытое поле для общей суммы
    var totalDisplay = $('.total-with-fee .ginput_container .ginput_total'); // Элемент, отображающий сумму

    // Получаем текущую сумму из поля Total с комиссией
    var currentTotal = parseFloat(totalField.val()) || 0;

    // Проверяем, если комиссия уже была добавлена, то убираем её, иначе добавляем
    if (currentTotal === totalWithoutFee + fee) {
      // Убираем комиссию
      totalField.val(totalWithoutFee.toFixed(2));
      totalDisplay.text('$' + totalWithoutFee.toFixed(2));
    } else {
      // Добавляем комиссию
      var newTotal = totalWithoutFee + fee;
      totalField.val(newTotal.toFixed(2));
      totalDisplay.text('$' + newTotal.toFixed(2));
    }

    // Обновляем значение комиссии в поле
    $('#processing-fee-gravity').val('$' + fee.toFixed(2)); // Обновляем поле с комиссией
  });

  // Обработчик изменения поля "Total without fee" для автоматического обновления
  $('.total-without-fee .ginput_container input.gform_hidden').on(
    'change',
    function () {
      // Если значение поля изменилось, пересчитываем комиссию и обновляем итоговую сумму
      var totalWithoutFee =
        parseFloat(
          $('.total-without-fee .ginput_container .ginput_total')
            .text()
            .replace('$', '')
            .trim()
        ) || 0; // Получаем новое значение

      // Рассчитываем комиссию (0.03% от значения)
      var fee = totalWithoutFee * 0.03;

      // Получаем поле для общей суммы (скрытое поле с классом total-with-fee)
      var totalField = $(
        '.total-with-fee .ginput_container input.gform_hidden'
      ); // Скрытое поле для общей суммы
      var totalDisplay = $('.total-with-fee .ginput_container .ginput_total'); // Элемент, отображающий сумму

      // Обновляем поле Total и его отображение
      var newTotal = totalWithoutFee + fee;
      totalField.val(newTotal.toFixed(2));
      totalDisplay.text('$' + newTotal.toFixed(2));
    }
  );

  $(document).on('change', '.ginput_quantity', function () {
    let val = parseFloat($(this).val());
    $(this).val(isNaN(val) ? '' : Math.floor(val));
  });

  $(document).on('gform_confirmation_loaded', function (event, formId) {
    setTimeout(function () {
      var $header = $('.main-header');
      var $formHead = $('.form-block__head');
      var $confirmationMessage = $('#gform_confirmation_wrapper_' + formId);

      if ($confirmationMessage.length) {
        var headerHeight = $header.length ? $header.outerHeight() : 0;
        var formHeadHeight = $formHead.length ? $formHead.outerHeight() : 0;
        var totalOffset =
          $confirmationMessage.offset().top -
          headerHeight -
          formHeadHeight -
          20;

        $('html, body').stop().animate(
          {
            scrollTop: totalOffset,
          },
          600
        );
      }
    }, 100); // 100ms delay for correct height calculation
  });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */

  // Викликати функцію при зміні розміру вікна

  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  $('.copy-link-news-single').on('click', function (event) {
    event.preventDefault();

    const url = window.location.href;
    navigator.clipboard.writeText(url);
    // .then(function () {
    //   alert('URL copied to clipboard!');
    // })
    // .catch(function (err) {
    //   console.error('URL could not be copied: ', err);
    // });
  });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Scholarships tabs to dropdown
   */
  $('.tabs-options-block').hide();
  $('.block-choose__list-item-main').each(function () {
    $(this).click(function () {
      $(this).next('.tabs-options-block').slideToggle();
    });
  });

  $('.tabs-title').click(function () {
    $(this).parent('.tabs-options-block').slideToggle();
    $('.block-deadline').show();
  });

  // $('.all-funds-item .title').each(function () {
  //   $(this).click(function () {
  //     $(this).next('.all-funds-item .section').slideToggle();
  //     $(this).toggleClass('change-icon');
  //     $('.all-funds-item .section').toggleClass('show-list');
  //   });
  // });

  $('.all-funds-item .title').each(function () {
    $(this).click(function () {
      $(this).next('.section').slideToggle();
      $(this).toggleClass('change-icon');
    });
  });

  $('.acc-fund-list-type').each(function () {
    $(this).click(function () {
      $(this).toggleClass('change-icon');
      $(this).next('.acc-fund-list-items').slideToggle();
      // $('.acc-fund-list-items').toggleClass('show-list');
    });
  });

  $('.community-list__title-button').click(function () {
    $(this)
      .closest('.community-list')
      .find('.community-list__wrapper')
      .slideToggle();
    $(this)
      .closest('.community-list__title')
      .toggleClass('community-list__title-active');
    if (
      $(this)
        .closest('.community-list__title')
        .hasClass('community-list__title-active')
    ) {
      $(this).text('Close List');
    } else {
      $(this).text('Expand List');
    }
  });

  $('.singular-fund-type__title').each(function () {
    $(this).click(function () {
      $(this).next('.singular-fund-type__fund').slideToggle();
      $(this).toggleClass('active-type__fund');
    });
  });

  /**
   * Donate Now Page Settings
   */
  let scrolled = false;
  $('.payment-method-paypal__button').on('click', function () {
    $('.stripe-payment-form').hide();
    $('.paypal-payment-form').show();
    if (!scrolled) {
      $('html, body').animate(
        {
          scrollTop: $(window).scrollTop() + 800,
        },
        500
      );

      scrolled = true;
    }
  });

  $('.payment-method-stripe__button').on('click', function () {
    $('.paypal-payment-form').hide();
    $('.stripe-payment-form').show();
    if (!scrolled) {
      $('html, body').animate(
        {
          scrollTop: $(window).scrollTop() + 850,
        },
        500
      );

      scrolled = true;
    }
  });

  $('#apply-fee').on('click', function () {
    const fee = parseFloat($('#processing-fee').val().replace('$', ''));
    const totalDonationElem = $('#fund-list-donation-total');
    let currentTotal = parseFloat(totalDonationElem.text().replace('$', ''));
    let stripeFee = $('#processing-fee').attr('data-stripe');
    let curentHiddenFee = $('input[name="donationTotal"]').val();

    // Check if the fee has already been applied
    if (!totalDonationElem.data('feeApplied')) {
      currentTotal += fee;
      totalDonationElem.text(`$${currentTotal.toFixed(2)}`);
      totalDonationElem.data('feeApplied', true); // Mark as fee applied

      $(this).addClass('button-applied');

      // Set the value to the hidden field
      $('input[name="StripeConvenienceFee"]').val(fee.toFixed(2));
      $('input[name="donationTotal"]').val(+stripeFee + +curentHiddenFee);
    }
  });

  /**
   * Grant App form scroll
   */

  $(document).on(
    'click',
    '.gform_next_button, .gform_previous_button',
    function () {
      $('html, body').animate(
        {
          scrollTop: $(window).scrollTop() - 200,
        },
        600
      );
    }
  );

  /**
   * Section with class "text-image-container" decor line dynamic width
   */

  function updateLineWidth() {
    let titleTextWidth = $('.tic-title__text').outerWidth();
    let totalWidth = $('.tic-title').width();

    $('.tic-line').css({
      width: totalWidth - titleTextWidth - 40 + 'px',
    });
  }

  updateLineWidth();

  $(window).resize(function () {
    updateLineWidth();
  });

  /**
   * Custom fund donation range
   */

  function getSafeValue(val) {
    return isNaN(val) ? 0 : val;
  }

  function formatValue(value) {
    return parseFloat(value).toFixed(2);
  }

  $('.increment').on('click', function () {
    var $input = $(this).siblings('.for-custom-arrows');
    var currentVal = getSafeValue(parseFloat($input.val()));
    var newVal = currentVal + 0.01;
    $input.val(formatValue(newVal));
    $input.trigger('change');
  });

  $('.decrement').on('click', function () {
    var $input = $(this).siblings('.for-custom-arrows');
    var currentVal = getSafeValue(parseFloat($input.val()));
    var newVal = currentVal - 0.01;
    $input.val(formatValue(newVal));
    $input.trigger('change');
  });

  /**
   * Add reset button to scholarships button
   */

  let $formFooter = $(
    '.scholarships .gform_footer,.form-block__form .gform_footer '
  );
  if ($formFooter.length) {
    let $resetButton = $('<input/>', {
      type: 'reset',
      value: 'Clear',
      class: 'reset-button',
    });

    $formFooter.append($resetButton);
  }

  $('.high-school-drop .gform_drop_instructions').text(
    'Download your Transcript from High School'
  );
  $('.resume-school-drop .gform_drop_instructions').text(
    'Download your Attach an applicant resume'
  );
  $('.ver-school-drop .gform_drop_instructions').text(
    'Download your submission of verification'
  );
  $('.receipt-file .gform_drop_instructions').text('Download your receipts');

  /**
   * Preheader search styles func
   */

  let $icon = $('.preheader-search__icon');
  let $form = $('.preheader-search__form');

  function showForm() {
    $form.addClass('show');
    $icon.hide();
  }

  function hideForm() {
    $form.removeClass('show');
    $icon.show();
  }

  $icon.on('mouseenter', function () {
    showForm();
  });

  $icon.on('mouseleave', function () {
    if (!$form.is(':hover')) {
      hideForm();
    }
  });

  $form.on('mouseenter', function () {
    showForm();
  });

  $form.on('mouseleave', function () {
    hideForm();
  });

  $('.red-subtitle-full').each(function () {
    let $wrapper = $('<div class="red-title-decor"></div>');
    $(this).wrap($wrapper);
  });

  /**
   * Login Form
   */

  $('.gfield--type-remember_me .gchoice_3').each(function () {
    let $wrapper = $(
      '<div class="remember-desc"><p>If You\'ve Lost Your Password Please Contact MCF</p></div>'
    );
    $(this).wrap($wrapper);
  });
  $('.login .ginput_container_text #input_1').attr(
    'placeholder',
    'Enter Your Username'
  );
  $('.login .ginput_container_text #input_2').attr(
    'placeholder',
    'Enter Your Password'
  );

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  $('.three-column-posts__posts-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    dots: true,
    arrows: false,
    adaptiveHeight: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: 'unslick',
      },
    ],
  });

  $('.two-videos__left-video').on('click', function (ev) {
    ev.preventDefault();
    //$('iframe').trigger('play');
    let symbol = $('iframe')[0].src.indexOf('?') > -1 ? '&' : '?';

    $('.two-videos__left-video').css('z-index', '-1');
    $('.two-videos__left-video iframe').css('z-index', '20');
    $('.two-videos__left-video iframe')[0].src += symbol + 'autoplay=1';
  });
  $('.two-videos__right-video').on('click', function (ev) {
    ev.preventDefault();
    //$('iframe').trigger('play');
    let symbol = $('iframe')[0].src.indexOf('?') > -1 ? '&' : '?';

    $('.two-videos__right-video').css('z-index', '-1');
    $('.two-videos__right-video iframe').css('z-index', '20');
    $('.two-videos__right-video iframe')[0].src += symbol + 'autoplay=1';
  });
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }

  let isClicked = false;

  function isElementInViewport(el) {
    const rect = el[0].getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  $(window).on('scroll', function () {
    let $loadMoreButton = $('.facetwp-load-more');

    if ($loadMoreButton.length > 0) {
      if (isElementInViewport($loadMoreButton) && !isClicked) {
        $loadMoreButton.click();
        isClicked = true;
      }

      if (!isElementInViewport($loadMoreButton) && isClicked) {
        isClicked = false;
      }
    }
  });
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
